import http from '@/http-common'
import {ApiService} from '@/services/ApiService'

const URL = '/api/region'

class RegionService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
    getRegions(regionType) {
      return http.post(`${URL}`, {'regionType': regionType})
    }
}

export default new RegionService()