<template v-if="formActive">

    <FormItem id="name" :label="$t('overview.name')" v-model="region.name" :required="true" />
    <FormItem id="code" :label="$t('overview.code')" v-model="region.code" :required="true" />    
    <FormItem id="voyage_status_id" :label="$t('overview.type')" v-model="region.region_type_id" type="buttongroup" sizeItem="4" :options="regiontypes" />
    
</template>
                    


<script>
    import dataStore from '@/store/data';

    export default {
        name: 'RegionForm',
        props: ['itemData'],
        data() {
            return {
                region: {},
                regiontypes: [],
                submitted: false,
                loading: false,
                invalid: {},
                formActive: false
            }
        },
        watch: {
            itemData: function (val) {
                this.region = val    
            },
        },
        methods: {
            active(value){
                this.formActive = value;
            },
            validate(){
                let valid = true;
                let required = document.querySelectorAll('#formModal input[required],#formModal select[required]');

                for(let field of required) {
                    if(!field.value || field.value == this.$t('form.select')) {
                        field.classList.add('is-invalid');
                        valid = false;
                    }
                    else{
                        field.classList.remove('is-invalid');
                    }
                }
                if(!valid) alert(this.$t('errors.empty_fields'));
                return valid;
            },
            getData() {
                return this.region;
            },
            setData(data) {
                this.region = data
            },
            reset() {
                this.region = {
                    'name': null,
                    'code': null,
                    'region_type_id': null
                },
                
                this.loading = false
                this.invalid = {}
                this.getRegiontypes()
            },
            getRegiontypes() {
                this.regiontypes = dataStore.getters.getRegionTypes;
            }

        }
    }
</script>