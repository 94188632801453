<template>
    <Overview 
        :moduleName="this.formatModuleName(this.$route.name, this.$i18n, false)"
        :moduleNamePlural="this.formatModuleName(this.$route.name, this.$i18n)"
        :sort="'name'"
        :itemService=itemService
        :itemFormRef=itemFormRef
        :selectFields=selectFields
        :filterOptions="filterOptions"
    >
        <template #columns>
            <Column field="name" :header="$t('overview.name')"></Column>
            <Column field="code" :header="$t('overview.code')" style="width: 40%"></Column>
            <Column field="region_type.name" :header="$t('overview.region_type')" style="width: 40%"></Column>
        </template>
        

        <template #form>
            <RegionForm ref="ItemForm" :itemData="item" />
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from '@/components/column';
import RegionForm from '@/components/region/RegionForm.vue';
import regionService from '@/services/RegionService';
import store from '@/store/user';

export default {
    components: {
        Overview,
        Column,
        RegionForm
    },
    data() {
        return {
            item: {},
            itemService: regionService,
            itemFormRef: null,
            selectFields: [
                "id",
                "name",
                "code",
                "region_type_id",
                {
                    "regionType": [
                        "id",
                        "name"
                    ]
                }
            ],
            filterOptions: [
                {
                    field: 'region_type_id',
                    radio: [{id: 1, name: this.$t('service.1')}, {id: 2, name: this.$t('service.2')}],
                    name: this.$t('overview.type')
                }
            ],
        }
    },
    mounted() {
        if(!store.getters.getCompanyHq){
            window.location.href = '/';
            return;
        }
        this.itemFormRef = this.$refs.ItemForm;
    }
}
</script>